<template>
  <header class="text-left h-14 flex justify-between font-bold items-center border-b border-gray">
    <button @click="$router.back" class="flex select-none items-center justify-start space-x-2 p-2 px-3">
      <i class="icon-arrowLeft" />
      <span>Atrás</span>
    </button>
    <transition mode="out-in" name="fade">
      <button
        class="font-medium px-4"
        :class="{
        'text-red-500 active:bg-red-200 active:bg-opacity-50': !restaurant.blacklisted,
        'text-primary active:bg-blue-200 active:bg-opacity-50': restaurant.blacklisted,
      }"
        v-if="restaurant !== null && !loadings.blacklisted"
        @click="markIgnored">
        {{ restaurant?.blacklisted ? 'Dejar de ignorar' : 'Ignorar' }}
      </button>
      <div class="px-4" v-else>
        <a-loader class="w-6 h-6" />
      </div>
    </transition>
  </header>

  <div>
    <section class="px-4 py-4 text-left space-y-2 border-b border-gray">
      <div class="flex items-start">
        <div class="flex-none left-0 mr-1" style="min-width: 72px">
          <a-initials :name="restaurant.name" :src="restaurant.profile_picture" :desing-class="'custom-width'"></a-initials>
        </div>
        <div class="flex-auto pl-1">
          <div class="flex items-center">
            <i :class="restaurant.foodie_is_interested ? 'icon-heart text-red-400' : 'icon-heartEmpty'"/>
            <p class="pl-2 text-lg text-black">
              {{ restaurant.name }}
            </p>
          </div>
          <div v-if="restaurant.ig_username" class="flex font-light items-center">
            <i class="icon-instagram pr-2" />
            <p>{{ '@' + restaurant.ig_username }}</p>
          </div>
          <div class="flex font-light items-center">
            <i class="icon-phone pr-2" />
            <p>{{ ` ${restaurant.phone}`}}</p>
          </div>
          <Rating v-model="restaurant.rating" :stars="5" :cancel="false" :readonly="true"/>
        </div>
        <div class="flex flex-col text-primary flex-shrink-0">
          <button @click.prevent.stop="markInterested"
                  :class="`${restaurant.foodie_is_interested ? 'bg-gray text-white' : 'border-primary text-primary'}`"
                  class="px-2 py-1 mb-2 border rounded-full inline-block text-center">
            <p v-if="!loadings.interested">{{ restaurant.foodie_is_interested ? 'Retirar' : 'Colab' }}</p>
            <a-loader class="w-5 h-5 block mx-auto" v-else />
          </button>
          <router-link :to="{ name: 'collaborations.index', query: { restaurant: restaurant.id } }" class="px-2 py-1 text-white rounded-full bg-primary inline-block text-center">
            Ver colabs
          </router-link>
        </div>
      </div>

      <sololink-button :restaurant="restaurant" v-if="restaurant?.covermanager_id"/>

      <p class="text-sm">
        {{ restaurant?.biography }}
      </p>
      <div v-if="restaurant?.address" class="text-lg txt_addres break-words">
        <i class="fas fa-map-marker-alt pr-1" />
        <p class="inline-block">{{ restaurant.address }}</p>
        <p v-if="restaurant.city" class="inline-block">{{ `, ${restaurant?.zip_code} ${restaurant.city}` }}</p>
        <p v-if="restaurant.state" class="inline-block">{{ `, ${restaurant.state}` }}</p>
      </div>
    </section>
    <section class="grid grid-cols-3 py-3">
      <div class="flex flex-col flex-grow flex-wrap">
        <h4 class="text-lg">{{ restaurant.ig_followers_count ? restaurant.ig_followers_count : 0 }}</h4>
        <h4 class="text-gray leading-tight">Followers </h4>
      </div>
      <div class="flex flex-col flex-grow flex-wrap">
        <h4 class="text-lg">{{ restaurant.completed_collabs_count ? restaurant.completed_collabs_count : 0 }}</h4>
        <h4 class="text-gray leading-tight">Colaboraciones</h4>
      </div>
      <div class="flex flex-col flex-grow flex-wrap">
        <h4 class="text-lg">{{ restaurant.ig_media_count ? restaurant.ig_media_count : 0 }}</h4>
        <h4 class="text-gray leading-tight">Publicaciones</h4>
      </div>
    </section>
<!--    <div class="make_galery relative">-->
<!--      <template v-if="restaurant.ig_media && restaurant.ig_media.length > 0">-->
<!--        <div class="galery_grid" v-for="picture in restaurant.ig_media" :key="picture" :style="{background:`url(${picture})`}">-->
<!--        </div>-->
<!--      </template>-->
<!--      <template v-else>-->
<!--        <div class="placeholder_img" :style="{background:`url(${defaultplaceholder})`}">-->
<!--        </div>-->
<!--      </template>-->
<!--    </div>-->
    <div class="p-normal flex flex-col justify-center mt-32">
      <div class="btn__container">
        <a :href="`https://instagram.com/${restaurant.ig_username}`" class="btn" target="_blank">
          <i class="fab fa-instagram"></i>
          <span class="text-xl">Ver en instagram</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Rating from 'primevue/rating'
import SololinkButton from './sololink-button.vue'
/**
 * TODO
 * Agregar loader
 */
export default {
  data: () => ({
    restaurant: {},
    adviced: false,
    loadings: {
      interested: false,
      blacklisted: false,
      restaurant: false
    },
    ignoring: null,
    defaultLogo: require('@/assets/logo.png'),
    defaultplaceholder: require('@/assets/placeholde_images.svg')
  }),
  components: {
    Rating,
    SololinkButton
  },
  mounted () {
    this.loadings.restaurant = !this.loadings.restaurant
    this.$repository.restaurants.show(this.$route.params.id, { with_count: 'completedCollabs' })
      .then(({ data: restaurant }) => {
        this.restaurant = restaurant
      })
      .finally(() => (this.loadings.restaurant = !this.loadings.restaurant))
  },
  computed: mapState({
    user: ({ session }) => session.user
  }),
  methods: {
    markIgnored () {
      const action = this.restaurant.blacklisted ? 'detach' : 'attach'
      this.loadings.blacklisted = !this.loadings.blacklisted
      this.$repository.foodies
        .setBlacklisted(this.user.foodie.id, {
          [action]: [this.restaurant.id]
        })
        .then(() => {
          this.restaurant.blacklisted = !this.restaurant.blacklisted
        })
        .finally(() => {
          this.ignoring = null
          this.loadings.blacklisted = !this.loadings.blacklisted
        })
    },
    markInterested () {
      // const action = this.restaurant.foodie_is_interested ? 'detach' : 'attach'
      this.loadings.interested = !this.loadings.interested
      // this.$repository.foodies
      //   .markInterested(this.user.foodie.id, {
      //     [action]: [this.restaurant.id]
      //   })
      //   .then(() => {
      //     this.restaurant.foodie_is_interested = !this.restaurant.foodie_is_interested
      //   })
      //   .finally(() => (this.loadings.interested = !this.loadings.interested))
      if (!this.restaurant.foodie_is_interested) {
        this.$repository.foodies
          .markInterested(this.user.foodie.id, { restaurant_id: this.restaurant.id })
          .then(() => {
            this.restaurant.foodie_is_interested = !this.restaurant.foodie_is_interested
          })
          .finally(() => (this.loadings.interested = !this.loadings.interested))
      } else {
        this.$repository.foodies
          .unmarkInterested(this.user.foodie.id, this.restaurant.id)
          .then(() => {
            this.restaurant.foodie_is_interested = !this.restaurant.foodie_is_interested
          })
          .finally(() => (this.loadings.interested = !this.loadings.interested))
      }
    }
  }
}
</script>

<style lang="scss"  scoped>

.make_galery{
  display: inline-block;
  width: calc( 100% - 5px);
  text-align: left;
  margin-left: 5px;
  .galery_grid{
    display:inline-block;
    width: calc(33% - 5px);
    position: relative;
    height: 0px;
    padding-top: 32.341%;
    background-size: cover !important;
    margin-right: 5px;
    background-position: 50% 50% !important;
  }
  .placeholder_img{
    display: block;
    position: relative;
    height: calc(100vh - 333px);
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }
}
.txt_addres{
color: #707070;
font-size: 13px;
}
.border-green-200{
  border-color:#23BB2D ;
}
.text-green-200{
  color:#23BB2D
}

.loader_white{
  width: 100%;
  position: absolute;
  background: rgba(255,255,255,.8);
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  z-index: 10;
}
.instagram_btn{
  margin: 0 auto;
  height: 55px;
  width: 265px;
  border-radius: 55px;
  background: #d6249f;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
  box-shadow: 0px 3px 10px rgba(0,0,0,.25);
}
.custom-width{
  min-width: 72px;
  max-width: 72px;
  width: 72px;
  height: 72px;
}
////
.btn__container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn {
  min-width: 110px;
  background: linear-gradient(to right, #ff3019 0%,#c90477 100%);
  padding: 1rem 2rem;
  text-decoration: none;
  color: #fff;
  display: flex;
  transition: all .2s ease-in-out;
  margin-right: 10px;

  i {
    color: #fff;
    font-size: 20px;
    padding-right: 10px;
    transition: all .3s ease-in-out;
  }
  span {
    font-family: "Roboto", sans-serif;
    align-self: center;
    transform: translateX(0px);
    transition: all .1s ease-in-out;
    opacity: 1;
  }
}
</style>
