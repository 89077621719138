<template>
  <button @click="handleClick" :disabled="fetching" style="width: 60%; padding-top: 5px; padding-bottom: 5px;" :class="`border select-none flex justify-center rounded-full w-full text-center text-green-200 ${'border-green-200'}`">
    <div v-if="fetching | storing | deleting" class="space-x-2 flex items-center">
      <a-loader class="w-4 h-4" color="text-green-400" />
      <span class="animate-pulse">
        {{ storing || deleting ? 'Procesando...' : 'Buscando reservas' }}
      </span>
    </div>
    <template v-else>
      {{ hasSololink ? 'Remover sololink' : 'Añadir a mis reservas' }}
    </template>
  </button>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useArray, useRepository, useSession } from '../../../../hooks'

export default defineComponent({
  props: {
    restaurant: { required: false, type: Object, default: null }
  },
  setup () {
    const fetching = ref(false)
    const storing = ref(false)
    const deleting = ref(false)
    const { user } = useSession()
    const { fetchLinks, storeLink, deleteLink } = useRepository(({ sololinks, foodies, users }) => ({ // eslint-disable-line
      fetchLinks: users.igUsername,
      storeLink: foodies.sololinks(user?.foodie?.id).store,
      deleteLink: sololinks.delete
    }))
    const { params } = useRoute()
    const [links, { set: setLinks }] = useArray()

    const hasSololink = computed(() => links.value.some(link => link.restaurant_id === parseInt(params.id)))

    const fetch = () => {
      fetching.value = !fetching.value
      fetchLinks(user?.foodie?.ig_username)
        .then(({ data: { solo_links: links } }) => links)
        .then(setLinks)
        .finally(() => (fetching.value = !fetching.value))
    }

    const store = () => {
      storing.value = !storing.value
      storeLink({ restaurant_id: params.id })
        .then(fetch)
        .finally(() => (storing.value = !storing.value))
    }

    const remove = () => {
      deleting.value = !deleting.value
      const link = links.value.find(link => link.restaurant_id === parseInt(params.id))
      deleteLink(link.id)
        .then(fetch)
        .finally(() => (deleting.value = !deleting.value))
    }

    onMounted(() => {
      fetch()
    })

    const handleClick = () => {
      if (!hasSololink.value) {
        store()
      } else {
        remove()
      }
    }

    return {
      hasSololink,
      fetching,
      storing,
      deleting,
      handleClick
    }
  }
})
</script>
